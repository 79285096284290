import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withLocation from "./withLocation"
import Listing, { MeetingSpaceFilter } from "./listing"
import SearchFormAlter from "./search/searchalter"
import { navigate } from "gatsby"
import GoogleMapsAutocomplete from "./search/googleautosuggest"
import Axios from "axios"
const SearchComponent = ({ search }) => {
  var { city } = search
  var { lat } = search
  var { lng } = search
  var { spaceType } = search
  var { location } = search
  var { capacity } = search
  const [listingFilter, setlistingFilter] = useState([])
  const [inCity, setinCity] = useState([])
  const [screenSize, setScreenSize] = useState([])

  const [latValue, setlatValue] = useState("")
  const [lngValue, setlngValue] = useState("")
  const [buttonState, setButtonState] = useState(false)
  const [firsturl, setfirsturl] = useState("")

  const citySelect = <option value={city}>{city}</option>

  useEffect(() => {
    if (listingFilter == "") {
      setlistingFilter(spaceType)
    }
  }, [listingFilter])

  useEffect(() => {
    if (inCity == "") {
      setinCity(city)
    }
  })

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  useEffect(() => {
    function handleResize() {
      const { width } = getWindowDimensions()

      if (width >= 980 && width < 1440) {
        setScreenSize("medium")
      } else if (width >= 1440 && width < 1981) {
        setScreenSize("large")
      } else if (width >= 1981) {
        setScreenSize("xlarge")
      } else {
        setScreenSize("small") // You might want to handle cases smaller than 980px
      }
    }

    window.addEventListener("resize", handleResize)

    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  function changeSpaceType(e) {
    // setspacetype(e.target.value)
    if (!e.target.value) {
    }
  }

  function changeCity(e) {}

  const SearchResult = () => {
    navigate(firsturl)
  }

  // Center the autocomplete to Bengaluru by default
  // const bengaluruCenter = { lat: 12.9716, lng: 77.5946 }

  const geoLocation = (lat, lng, locality) => {
    console.log("geocode", lat, lng)
    setlatValue(lat)
    setlngValue(lng)
    setfirsturl(
      "/search?city=" +
        city +
        "&lat=" +
        lat +
        "&lng=" +
        lng +
        "&spaceType=" +
        spaceType +
        "&location=" +
        locality
    )
    console.log(firsturl)
    setButtonState(true)
  }
  const TypeSpace = type => {
    switch (type) {
      case "meetingSpace":
        return "Meeting Space"
        break
      case "dailyofficeSpace":
        return "Coworking Day Pass"
        break
      case "monthlyofficeSpace":
        return "Monthly Office Space"
        break
      default:
        return "Meeting Space"
        break
    }
  }

  return (
    <div className="row ">
      <div className="container-fluid noPadding">
        {city !== "" && lat !== "" && lng !== "" ? (
          <Listing
            city={city}
            lat={lat}
            lng={lng}
            location={location}
            spacetype={spaceType}
            capacity={capacity}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

SearchComponent.propTypes = {
  search: PropTypes.object,
}

export default withLocation(SearchComponent)
